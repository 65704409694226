.sF{
    color: rgb(255, 245, 46);
}

.gW {
    color: rgb(128, 128, 128);
}
.mD {
    color: rgb(78, 0, 167);
}
.lM {
    color:rgb(255, 0, 0);
}
.bS {
    color:rgb(223, 132, 57);
}
