form {
  padding: 25px 0px;
}

input,
textarea {
  background-color: transparent;
  border-bottom: 1px solid white;
  resize: none;
  transition: 250ms ease-in-out;
  padding: 20px 10px !important;
}

input::placeholder,
textarea::placeholder {
  color: white !important;
}

input:focus,
textarea:focus {
  color: black !important;
  background-color: white;
  padding: 20px 10px !important;
}

button {
  margin: 25px 0px !important;
}

.submit-btn {
  font-size: 16px;
  background: transparent;
  color: white;
  padding: 5px 15px;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid white;
  transition: 250ms ease-in-out;
}

.submit-btn:hover {
    background-color: white;
    border-color: transparent;
    color: black;
    font-weight: bold;
}

.arrow-down {
  fill: #fff;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-direction: alternate;
}

@keyframes bounce {
  from {
    transform: translateY(-2px);
  }
  to {
    transform: translateY(5px);
  }
}
