@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://api.fontshare.com/v2/css?f[]=boska@900,901,701,201,401,301,500,400,2,1,200,700,300,501&display=swap"rel="stylesheet");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway+Dots&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

:root {
  --white-color: #ffffff;
  --background: #161a1c;
  --secondary-bg: #d9d9d9;
  --text: #d2dbde;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  font-size: 12px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--background);
  color: var(--text);
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}

.flipGengar {
  transform: scaleX(-1);
}

.scrollable-element {
  padding: 9rem 0 5rem 0;
}

.scrollabl-element::-webkit-scrollbar {
  display: none;
}

.aboutMeSection::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}


.projectBtn {
  border: 1px solid white;
  padding: 1rem 2rem;
  transition: 250ms ease-in-out;
}

.projectBtn:hover {
  border-color: black;
  background-color: white;
  color: black;
}

.filterOn {
  filter: grayscale(100%);
  transition: 250ms ease-in-out;
}

.aboutHover:hover .filterOn{
  filter: grayscale(0%);
}

.aboutHover p {
  transition: 250ms ease-in-out;
}

.aboutHover:hover p{
  color: white;
}