header {
  background-color: #161a1c;
}

.logo {
  width: 100%;
}

.header_projects,
.header_contact,
.header_about {
  transition: 250ms ease-in-out;
}

.header_projects:hover,
.header_contact:hover,
.header_about:hover {
  color: #D5ADCC;
}

.line__across-header {
  position: relative;
}

.line__across-header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 4px;
  background-color: #aa8aa3;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.line__across-header:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
